<template>
  <v-app>
    <div class="login-bg"></div>
    <div class="login-header position-absolute">
      <div class="d-flex justify-space-between align-center pa-2">
        <a href="/v2">
          <img :src="require('@/assets/common/logo.png')" alt="" />
        </a>
        <a class="warning--text link-service" href="/v2/services">
          {{ $t("labels.terms_of_use") }}
        </a>
      </div>
    </div>
    <v-container fluid class="ma-auto">
      <div class="position-relative mb-5 white--text text-center">
        <h1 class="home-title">
          {{ $t("labels.ssc_slogan") }}
        </h1>
        <h2 class="home-desc">
          {{ $t("labels.hotline") }}:
          <a class="warning--text" href="tel:0769995555">076.999.5555</a> |
          {{ $t("labels.email") }}:
          <a class="warning--text" href="mailto:hello@ssc.eco">hello@ssc.eco</a>
        </h2>
      </div>
      <v-card max-width="480" class="mx-auto">
        <!--<v-toolbar color="warning" dark flat>
          <v-toolbar-title>Đăng nhập</v-toolbar-title>
        </v-toolbar>-->

        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                :label="$t('labels.account_name')"
                prepend-icon="mdi-account"
                hide-details
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                :label="$t('labels.password')"
                type="password"
                prepend-icon="mdi-lock"
                hide-details
                autocomplete="off"
                @keyup.enter="login"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn
                block
                color="warning"
                :disabled="isDisabledBtn"
                @click="login"
              >
                {{ $t("labels.login") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Login",
  data: () => ({
    email: null,
    password: null,
  }),
  computed: {
    isDisabledBtn() {
      return !this.email || !this.password;
    },
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    checkLogin() {
      const sct = localStorage.getItem("_sct");
      if (sct) {
        this.$router.push({ name: "Dashboard" });
      }
    },
    async login() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/login", {
          email: this.email,
          password: Buffer.from(this.password, "utf8").toString("base64"),
        });

        this.isLoading = false;
        localStorage.setItem("_sct", data.token);
        this.$vToastify.success(this.$t("messages.login_success"));
        await this.$router.push({ name: "Dashboard" });

        /* const v1data = await httpClient.get(
          `${process.env.VUE_APP_WEB_V1_URL}/site/login-v2`,
          {
            bsk: Buffer.from(data.key, "utf8").toString("base64"),
          }
        );
        this.isLoading = false;
        if (v1data === true) {
          localStorage.setItem("_sct", data.token);
          this.$vToastify.success(this.$t("messages.login_success"));
          await this.$router.push({ name: "Dashboard" });
        } else {
          this.$vToastify.error(this.$t("messages.system_error"));
        } */
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
